import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import PageHeader from "../../components/layout/PageHeader"
import SupportPage from "../../components/layout/SupportPage"

const Instructions = styled.ol`
    li {
        margin-bottom: 20px;
    }
`

const PermitGuidePage = () => {
	return (
		<SupportPage page="resources" title="Help Guide Getting Your Sign Permit">
			<PageHeader title="Sign Permit Guide" />

			<p>
				There is no need to be intimidated about getting a sign permit for your commercial business sign. The easiest
				way to start is to Google “sign permit (insert your location)” and contact the planning department. Remember
				some Municipalities also require you to go through the building department as well. Even if the City
				Municipality does not require a permit the county may, so be sure to check with them as well. It's not as
				difficult as it seems and we’re always available to help, just give us a call.&nbsp;&nbsp;
			</p>

			<Instructions>
				<li>
					Most leases for retail / commercial space have a sign addendum that provides very general landlord
					guidelines for signage. The sign addendum will also tell you whether or not the landlord must approve the
					sign <u>before</u> it is ordered or installed.&nbsp;&nbsp;
				</li>
				<li>
					The municipality defines most sign specifications; i.e., square footage, height, type of lighting, placement on
					the wall, etc. Every municipality is slightly different so some amount of research is usually required.&nbsp;&nbsp;
				</li>
				<li>
					Pay close attention to time frames. If you want your sign in place on a specific date you need to make
					certain that permitting can be done and the sign fabricated by that date.&nbsp;&nbsp;
				</li>
				<li>
					Now that you have made all of the buying decisions, it's time to assemble the documentation and fill out
					the forms. Commonly requested items can include:
					<ul>
						<li>
							<h3>Sign Permit Application</h3>
							This can usually be downloaded from the City website
						</li>
						<li>
							<h3>Site plan for building</h3>
							A simple drawing as if you were looking from space, showing the building and sign location.
							Include an arrow pointing North on your drawing.&nbsp;&nbsp;
						</li>
						<li>
							<h3>Linear footage of property/Tenant Space/Building</h3>
							This is the width of the actual location business or tenant location.&nbsp;&nbsp;
						</li>
						<li>
							<h3>Value of the sign</h3>
							Actual retail price of the sign, this is used to determine the price of the sign permit and tax.
						</li>
						<li>
							<h3>Scaled Elevation Drawings/Photo</h3>
							We provide for free, you supply us with a straight on picture of the installation Location.
						</li>
						<li>
							<h3>Electrical Schematic</h3>
							We provide you with this free of charge.
						</li>
					</ul>
				</li>
				<li>
					Take the completed package and your Survey Worksheet to your municipality. Make sure that your contact
					information is on the application so that you can be contacted with any questions. Before leaving ask when
					you should receive notification or approval.
				</li>
			</Instructions>

		</SupportPage>
	)
}

export default PermitGuidePage